/* eslint-disable no-loop-func */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import WestIcon from "@mui/icons-material/West";
import { firebase } from "../../database/firebase";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { viewallModules } from "../../Api/modules";
import { createCourses } from "../../Api/courses";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { IoMdCheckboxOutline } from "react-icons/io";
import { viewallstaff } from "../../Api/staff";
import { CreateCourseproject } from "../../Api/courseproject";

const Course = () => {
  const navigate = useNavigate();
  const [formdata, setformdata] = useState({
    title: "",
    description: "",
    shortdescription: "",
    duration: "",
    icon: "",
    author: "",
    price: "",
    offerprice: "",
    syllabus: "",
    introvideo: "",
    batch: "",
    amount_split: "",
  });
  const [disablebtn, setdisablebtn] = useState(false);
  const [lessondata, setlessondata] = useState([]);
  const [page, setpage] = useState("1");
  const [selectedlesson, setselectedlesson] = useState([]);
  const [staffdata, setstaffdata] = useState([]);
  const [techdata, settechdata] = useState([]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setformdata((values) => ({ ...values, [name]: value }));
  };
  const geturl = async (e) => {
    toast.info("Please Wait Image is uploading...", {
      autoClose: 2000,
      transition: Slide,
    });
    const fileSize = e.target.files[0].size;
    const fileMb = fileSize / 1024 ** 4;
    if (fileMb >= 2) {
      toast.error("Please select a file less than 4MB.", {
        autoClose: 5000,
        transition: Slide,
      });
    } else {
      let file = e.target.files;
      let file13 = new Promise((resolve, reject) => {
        var storageRef = firebase.storage().ref("course/" + file[0].name);
        storageRef.put(file[0]).then(function (snapshot) {
          storageRef.getDownloadURL().then(function (url) {
            setTimeout(() => resolve(url), 1000);
          });
        });
      });
      var imgurl1 = await file13;
      if (imgurl1 !== null) {
        toast.success("Image uploaded...", {
          autoClose: 5000,
          transition: Slide,
        });
        setformdata((values) => ({ ...values, icon: imgurl1 }));
      }
    }
  };

  const getproject = async (e) => {
    toast.info("Please Wait Syllabus is uploading...", {
      autoClose: 2000,
      transition: Slide,
    });
    const fileSize = e.target.files[0].size;
    const fileMb = fileSize / 1024 ** 4;
    if (fileMb >= 2) {
      toast.error("Please select a file less than 4MB.", {
        autoClose: 5000,
        transition: Slide,
      });
    } else {
      let file = e.target.files;
      let file13 = new Promise((resolve, reject) => {
        var storageRef = firebase.storage().ref("course/" + file[0].name);
        storageRef.put(file[0]).then(function (snapshot) {
          storageRef.getDownloadURL().then(function (url) {
            setTimeout(() => resolve(url), 1000);
          });
        });
      });
      var imgurl1 = await file13;
      if (imgurl1 !== null) {
        toast.success("Image uploaded...", {
          autoClose: 5000,
          transition: Slide,
        });
        setformdata((values) => ({ ...values, syllabus: imgurl1 }));
      }
    }
  };
  const handleChangeurl = async (e, id) => {
    toast.info("Please Wait Project icon is uploading...", {
      autoClose: 2000,
      transition: Slide,
    });
    const fileSize = e.target.files[0].size;
    const fileMb = fileSize / 1024 ** 4;
    if (fileMb >= 2) {
      toast.error("Please select a file less than 4MB.", {
        autoClose: 5000,
        transition: Slide,
      });
    } else {
      let file = e.target.files;
      let file13 = new Promise((resolve, reject) => {
        var storageRef = firebase.storage().ref("course/" + file[0].name);
        storageRef.put(file[0]).then(function (snapshot) {
          storageRef.getDownloadURL().then(function (url) {
            setTimeout(() => resolve(url), 1000);
          });
        });
      });
      var imgurl1 = await file13;
      if (imgurl1 !== null) {
        toast.success("Image uploaded...", {
          autoClose: 5000,
          transition: Slide,
        });
        setprojectdata({
          ...projectdata,
          [id]: imgurl1,
        });
      }
    }
  };

  const gettech = async (e) => {
    var imagearray = [];
    const files = Array.from(e.target.files);
    toast.info("Please Wait Image is uploading...", {
      autoClose: 2000,
      transition: Slide,
    });
    setdisablebtn(true);
    for (var i = 0; i < files.length; i++) {
      // eslint-disable-next-line no-loop-func
      let file13 = new Promise((resolve, reject) => {
        var storageRef = firebase.storage().ref("logo/" + files[i].name);
        storageRef.put(files[i]).then(function (snapshot) {
          storageRef.getDownloadURL().then(function (url) {
            setTimeout(() => resolve(url), 1000);
          });
        });
      });
      var imgurl1 = await file13;
      imagearray.push(imgurl1);
    }

    if (files.length === imagearray.length) {
      settechdata(imagearray);
      toast.success("Attachment Uploaded..", {
        autoClose: 2000,
        transition: Slide,
      });

      setdisablebtn(false);
    }
  };
  const getintro = async (e) => {
    toast.info("Please Wait Intro Video is uploading...", {
      autoClose: 2000,
      transition: Slide,
    });
    const fileSize = e.target.files[0].size;
    const fileMb = fileSize / 1024 ** 10;
    if (fileMb >= 2) {
      toast.error("Please select a file less than 10MB.", {
        autoClose: 5000,
        transition: Slide,
      });
    } else {
      let file = e.target.files;
      let file13 = new Promise((resolve, reject) => {
        var storageRef = firebase.storage().ref("course/" + file[0].name);
        storageRef.put(file[0]).then(function (snapshot) {
          storageRef.getDownloadURL().then(function (url) {
            setTimeout(() => resolve(url), 1000);
          });
        });
      });
      var imgurl1 = await file13;
      if (imgurl1 !== null) {
        toast.success("Intro Video uploaded Successfully...", {
          autoClose: 5000,
          transition: Slide,
        });
        setformdata((values) => ({ ...values, introvideo: imgurl1 }));
      }
    }
  };

  const savebtn = async () => {
    if (formdata.title.length === 0) {
      toast.error("Please enter Title...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.description.length === 0) {
      toast.error("Please enter Description...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.shortdescription.length === 0) {
      toast.error("Please enter Short Description...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.price.length === 0) {
      toast.error("Please enter Price...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.offerprice.length === 0) {
      toast.error("Please enter Offer Price...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.amount_split.length === 0) {
      toast.error("Please Select Amount Split...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.icon.length === 0) {
      toast.error("Please Upload Image...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.syllabus.length === 0) {
      toast.error("Please Upload Syllabus...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (techdata.length === 0) {
      toast.error("Please Upload Tech We Provide...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.batch.length === 0) {
      toast.error("Please Enter Next Batch...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.author.length === 0) {
      toast.error("Please Enter Trainer...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      setpage("2");
    }
  };
  useEffect(() => {
    getalllesson();
  }, []);
  const getalllesson = async () => {
    var alldata = await viewallModules();
    setlessondata(alldata);
    var viewall_staff = await viewallstaff();
    if (viewall_staff.length !== 0) {
      var filterdata = await viewall_staff.filter((data) => {
        return data.role === "Developer Team";
      });
      setstaffdata(filterdata);
    }
  };
  const handlelessonSelection = (lesson) => {
    const updatedSelectedlessons = [...selectedlesson];
    if (updatedSelectedlessons.includes(lesson)) {
      updatedSelectedlessons.splice(updatedSelectedlessons.indexOf(lesson), 1);
    } else {
      updatedSelectedlessons.push(lesson);
    }
    setselectedlesson(updatedSelectedlessons);
    if (updatedSelectedlessons.length !== 0) {
      var newdata = [];
      for (var i = 0; i < updatedSelectedlessons.length; i++) {
        for (var j = 0; j < lessondata.length; j++) {
          if (lessondata[j].id == updatedSelectedlessons[i]) {
            newdata.push({
              id: updatedSelectedlessons[i].toString(),
              name: lessondata[j].title,
            });
          }
        }
      }
      setItems(newdata);
    } else {
      setItems([]);
    }
  };
  const [items, setItems] = useState([]);
  const [projectdata, setprojectdata] = useState({
    project1_title: "",
    project1_des: "",
    project1_icon: "",
    project2_title: "",
    project2_des: "",
    project2_icon: "",
    project3_title: "",
    project3_des: "",
    project3_icon: "",
    project4_title: "",
    project4_des: "",
    project4_icon: "",
    project5_title: "",
    project5_des: "",
    project5_icon: "",
  });
  const removebtn = (id) => {
    setpage(id);
  };
  const submitbtn = async () => {
    if (selectedlesson.length === 0) {
      toast.error("Please Select Modules...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      setpage("3");
    }
  };
  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const reorderedItems = Array.from(items);
    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);
    setItems(reorderedItems);
  };
  const submit_btn = async () => {
    if (projectdata.project1_title.length === 0) {
      toast.error("Please enter Project 1 Title...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (projectdata.project1_des.length === 0) {
      toast.error("Please enter Project 1 Description...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (projectdata.project2_title.length === 0) {
      toast.error("Please enter Project 2 Title...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (projectdata.project2_des.length === 0) {
      toast.error("Please enter Project 2 Description...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (projectdata.project3_title.length === 0) {
      toast.error("Please enter Project 3 Title...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (projectdata.project3_des.length === 0) {
      toast.error("Please enter Project 3 Description...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (projectdata.project4_title.length === 0) {
      toast.error("Please enter Project 4 Title...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (projectdata.project4_des.length === 0) {
      toast.error("Please enter Project 4 Description...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (projectdata.project5_title.length === 0) {
      toast.error("Please enter Project 5 Title...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (projectdata.project5_des.length === 0) {
      toast.error("Please enter Project 5 Description...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      var durationdata = [];
      for (var i = 0; i < selectedlesson.length; i++) {
        var checkdata = await lessondata.filter((data) => {
          return data.id == selectedlesson[i];
        });
        if (checkdata.length !== 0) {
          durationdata.push(Number(checkdata[0].duration));
        }
      }
      var sumdata = durationdata.reduce((items, items2) => {
        return items + items2;
      }, 0);
      formdata["selectedmodule"] = JSON.stringify(selectedlesson);
      formdata["duration"] = sumdata;
      formdata["techdata"] = JSON.stringify(techdata);
      setdisablebtn(true);
      var createdata = await createCourses(formdata);
      if (createdata.message === "SUCCESS") {
        var newobj = {
          project1_title: projectdata.project1_title,
          project1_des: projectdata.project1_des,
          project1_icon: projectdata.project1_icon,
          project2_title: projectdata.project2_title,
          project2_des: projectdata.project2_des,
          project2_icon: projectdata.project2_icon,
          project3_title: projectdata.project3_title,
          project3_des: projectdata.project3_des,
          project3_icon: projectdata.project3_icon,
          project4_title: projectdata.project4_title,
          project4_des: projectdata.project4_des,
          project4_icon: projectdata.project4_icon,
          project5_title: projectdata.project5_title,
          project5_des: projectdata.project5_des,
          project5_icon: projectdata.project5_icon,
          course: createdata.data.id,
        };
        await CreateCourseproject(newobj);
        toast.success("Course Created Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        setdisablebtn(false);
        setTimeout(() => {
          navigate("/courses");
        }, 2000);
      }
    }
  };
  return (
    <div className="px-14 min-h-screen">
      {page === "1" ? (
        <div
          className="flex gap-2 items-center cursor-pointer"
          onClick={() => {
            navigate("/courses");
          }}
        >
          <WestIcon />
          <h1 className="text-2xl py-4 font-black">Create New Course</h1>
        </div>
      ) : page === "2" ? (
        <div
          className="flex gap-2 items-center cursor-pointer"
          onClick={() => {
            removebtn("1");
          }}
        >
          <WestIcon />
          <h1 className="text-2xl py-4 font-black">Create New Course</h1>
        </div>
      ) : (
        <div
          className="flex gap-2 items-center cursor-pointer"
          onClick={() => {
            removebtn("2");
          }}
        >
          <WestIcon />
          <h1 className="text-2xl py-4 font-black">
            Create New Course -project
          </h1>
        </div>
      )}
      <div className="w-full bg-white rounde shadow-2xl p-7 my-5 ">
        <div className="grid grid-cols-1 md:grid-cols-1 gap-8 mb-5">
          <div>
            {page === "1" ? (
              <>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Title
                  </h1>
                  <input
                    placeholder="Title"
                    type="text"
                    name="title"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.title}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Description
                  </h1>
                  <textarea
                    placeholder="Description"
                    type="text"
                    name="description"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.description}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Short Description
                  </h1>
                  <textarea
                    placeholder="Short Description"
                    type="text"
                    name="shortdescription"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.shortdescription}
                  />
                </div>

                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Price
                  </h1>
                  <input
                    placeholder="Price"
                    type="number"
                    name="price"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.price}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Offer Price
                  </h1>
                  <input
                    placeholder="Offer Price"
                    type="number"
                    name="offerprice"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.offerprice}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Course Amount Split
                  </h1>

                  <select
                    placeholder="Course Amount Split"
                    type="number"
                    name="amount_split"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.amount_split}
                  >
                    <option value={null}>Select Amount Split</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Image
                  </h1>
                  <input
                    placeholder="Phone Number"
                    type="file"
                    name="icon"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={geturl}
                    // defaultValue={formdata.icon}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Syllabus
                  </h1>
                  <input
                    placeholder="syllabus"
                    type="file"
                    name="syllabus"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={getproject}
                    // defaultValue={formdata.project}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Tech We Provide
                  </h1>
                  <input
                    placeholder="syllabus"
                    type="file"
                    name="syllabus"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={gettech}
                    multiple
                    // defaultValue={formdata.project}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg">Intro Video</h1>
                  <input
                    placeholder="Intro Video"
                    type="file"
                    name="syllabus"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={getintro}
                    accept="video/*"
                    // defaultValue={formdata.project}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Next Batch
                  </h1>
                  <input
                    placeholder="Next Batch"
                    type="date"
                    name="batch"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.batch}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                    Trainer
                  </h1>
                  <select
                    name="author"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.author}
                  >
                    <option>Select Trainer</option>
                    {staffdata.length !== 0
                      ? staffdata.map((data, index) => (
                          <option
                            key={index}
                            value={`${data.firstname} ${data.lastname}`}
                            selected={
                              formdata.author ==
                              `${data.firstname} ${data.lastname}`
                            }
                          >
                            {data.firstname} {data.lastname}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
              </>
            ) : page === "2" ? (
              <div className="py-3">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-5">
                  <div className="leftsidediv border-r-2 border-gray-300">
                    <h1 className="text-lg">Select Modules</h1>
                    {lessondata.length !== 0
                      ? lessondata.map((data, index) =>
                          selectedlesson.some(
                            (obj) => obj == data.id
                          ) ? null : (
                            <div className="py-2 pl-5" key={index}>
                              <input
                                type="checkbox"
                                id={`lesson-${index}`}
                                checked={selectedlesson.includes(data.id)}
                                onChange={() => handlelessonSelection(data.id)}
                              />
                              <label className="" htmlFor={`lesson-${index}`}>
                                {" "}
                                {data.title}
                              </label>
                            </div>
                          )
                        )
                      : null}
                  </div>
                  <div className="leftsidediv border-r-2 border-gray-300">
                    <h1 className="text-lg">Selected Modules</h1>
                    {/* {lessondata.length !== 0
                      ? lessondata.map((data, index) =>
                          selectedlesson.some((obj) => obj == data.id) ? (
                            <div className="py-2 pl-5" key={index}>
                              <input
                                type="checkbox"
                                id={`lesson-${index}`}
                                checked={selectedlesson.includes(data.id)}
                                onChange={() => handlelessonSelection(data.id)}
                              />
                              <label className="" htmlFor={`lesson-${index}`}>
                                {" "}
                                {data.title}
                              </label>
                            </div>
                          ) : null
                        )
                      : null} */}

                    <DragDropContext onDragEnd={handleDragEnd}>
                      <Droppable droppableId="droppable">
                        {(provided) => (
                          <ul
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className={
                              items.length !== 0
                                ? `sortable-list mt-5 bg-slate-300 w-max p-2 rounded`
                                : `sortable-list`
                            }
                          >
                            {items.map((item, index) => (
                              <Draggable
                                key={item.id}
                                draggableId={item.id}
                                index={index}
                              >
                                {(provided) => (
                                  <li
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className="list-item p-2 w-max"
                                  >
                                    <div
                                      className="flex items-center bg-black rounded p-2 w-[25rem] h-[3rem]"
                                      onClick={() => {
                                        handlelessonSelection(Number(item.id));
                                      }}
                                    >
                                      <div className="my-5 w-[25rem] bg-black text-center grid items-center font-bold text-lg rounded flex gap-4">
                                        <div className="flex gap-4">
                                          <button className="p-1 mr-2">
                                            <IoMdCheckboxOutline className="h-4 text-white" />
                                          </button>
                                          <div className="mr-2 text-sm text-white">
                                            {item.name}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </ul>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                </div>
              </div>
            ) : (
              <div className="py-3">
                <h1 className="text-lg">Add Project Information</h1>
                <div className="w-full bg-white rounde shadow-2xl p-7 my-5 ">
                  <h1 className="text-lg">Project 1</h1>
                  <div className="py-3">
                    <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                      Project Title
                    </h1>
                    <input
                      placeholder="Title"
                      type="text"
                      name="title"
                      className="border-2 p-2 w-full py-2 rounded"
                      onChange={(e) => {
                        setprojectdata({
                          ...projectdata,
                          project1_title: e.target.value,
                        });
                      }}
                      defaultValue={projectdata.project1_title}
                    />
                  </div>
                  <div className="py-3">
                    <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                      Project Description
                    </h1>
                    <textarea
                      placeholder="Project Description"
                      type="text"
                      name="title"
                      className="border-2 p-2 w-full py-2 rounded"
                      onChange={(e) => {
                        setprojectdata({
                          ...projectdata,
                          project1_des: e.target.value,
                        });
                      }}
                      defaultValue={projectdata.project1_des}
                    />
                  </div>
                  <div className="py-3">
                    <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                      Icon
                    </h1>
                    <input
                      placeholder="Title"
                      type="file"
                      name="title"
                      className="border-2 p-2 w-full py-2 rounded"
                      onChange={(e) => {
                        handleChangeurl(e, "project1_icon");
                      }}
                    />
                  </div>
                </div>
                <div className="w-full bg-white rounde shadow-2xl p-7 my-5 ">
                  <h1 className="text-lg">Project 2</h1>
                  <div className="py-3">
                    <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                      Project Title
                    </h1>
                    <input
                      placeholder="Title"
                      type="text"
                      name="title"
                      className="border-2 p-2 w-full py-2 rounded"
                      onChange={(e) => {
                        setprojectdata({
                          ...projectdata,
                          project2_title: e.target.value,
                        });
                      }}
                      defaultValue={projectdata.project2_title}
                    />
                  </div>
                  <div className="py-3">
                    <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                      Project Description
                    </h1>
                    <textarea
                      placeholder="Project Description"
                      type="text"
                      name="title"
                      className="border-2 p-2 w-full py-2 rounded"
                      onChange={(e) => {
                        setprojectdata({
                          ...projectdata,
                          project2_des: e.target.value,
                        });
                      }}
                      defaultValue={projectdata.project2_des}
                    />
                  </div>
                  <div className="py-3">
                    <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                      Icon
                    </h1>
                    <input
                      placeholder="Title"
                      type="file"
                      name="title"
                      className="border-2 p-2 w-full py-2 rounded"
                      onChange={(e) => {
                        handleChangeurl(e, "project2_icon");
                      }}
                    />
                  </div>
                </div>
                <div className="w-full bg-white rounde shadow-2xl p-7 my-5 ">
                  <h1 className="text-lg">Project 3</h1>
                  <div className="py-3">
                    <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                      Project Title
                    </h1>
                    <input
                      placeholder="Title"
                      type="text"
                      name="title"
                      className="border-2 p-2 w-full py-2 rounded"
                      onChange={(e) => {
                        setprojectdata({
                          ...projectdata,
                          project3_title: e.target.value,
                        });
                      }}
                      defaultValue={projectdata.project3_title}
                    />
                  </div>
                  <div className="py-3">
                    <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                      Project Description
                    </h1>
                    <textarea
                      placeholder="Project Description"
                      type="text"
                      name="title"
                      className="border-2 p-2 w-full py-2 rounded"
                      onChange={(e) => {
                        setprojectdata({
                          ...projectdata,
                          project3_des: e.target.value,
                        });
                      }}
                      defaultValue={projectdata.project3_des}
                    />
                  </div>
                  <div className="py-3">
                    <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                      Icon
                    </h1>
                    <input
                      placeholder="Title"
                      type="file"
                      name="title"
                      className="border-2 p-2 w-full py-2 rounded"
                      onChange={(e) => {
                        handleChangeurl(e, "project3_icon");
                      }}
                    />
                  </div>
                </div>
                <div className="w-full bg-white rounde shadow-2xl p-7 my-5 ">
                  <h1 className="text-lg">Project 4</h1>
                  <div className="py-3">
                    <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                      Project Title
                    </h1>
                    <input
                      placeholder="Title"
                      type="text"
                      name="title"
                      className="border-2 p-2 w-full py-2 rounded"
                      onChange={(e) => {
                        setprojectdata({
                          ...projectdata,
                          project4_title: e.target.value,
                        });
                      }}
                      defaultValue={projectdata.project4_title}
                    />
                  </div>
                  <div className="py-3">
                    <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                      Project Description
                    </h1>
                    <textarea
                      placeholder="Project Description"
                      type="text"
                      name="title"
                      className="border-2 p-2 w-full py-2 rounded"
                      onChange={(e) => {
                        setprojectdata({
                          ...projectdata,
                          project4_des: e.target.value,
                        });
                      }}
                      defaultValue={projectdata.project4_des}
                    />
                  </div>
                  <div className="py-3">
                    <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                      Icon
                    </h1>
                    <input
                      placeholder="Title"
                      type="file"
                      name="title"
                      className="border-2 p-2 w-full py-2 rounded"
                      onChange={(e) => {
                        handleChangeurl(e, "project4_icon");
                      }}
                    />
                  </div>
                </div>
                <div className="w-full bg-white rounde shadow-2xl p-7 my-5 ">
                  <h1 className="text-lg">Project 5</h1>
                  <div className="py-3">
                    <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                      Project Title
                    </h1>
                    <input
                      placeholder="Title"
                      type="text"
                      name="title"
                      className="border-2 p-2 w-full py-2 rounded"
                      onChange={(e) => {
                        setprojectdata({
                          ...projectdata,
                          project5_title: e.target.value,
                        });
                      }}
                      defaultValue={projectdata.project5_title}
                    />
                  </div>
                  <div className="py-3">
                    <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                      Project Description
                    </h1>
                    <textarea
                      placeholder="Project Description"
                      type="text"
                      name="title"
                      className="border-2 p-2 w-full py-2 rounded"
                      onChange={(e) => {
                        setprojectdata({
                          ...projectdata,
                          project5_des: e.target.value,
                        });
                      }}
                      defaultValue={projectdata.project5_des}
                    />
                  </div>
                  <div className="py-3">
                    <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                      Icon
                    </h1>
                    <input
                      placeholder="Title"
                      type="file"
                      name="title"
                      className="border-2 p-2 w-full py-2 rounded"
                      onChange={(e) => {
                        handleChangeurl(e, "project5_icon");
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="py-8">
          {page === "1" ? (
            disablebtn === false ? (
              <button
                className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5"
                onClick={savebtn}
              >
                Next
              </button>
            ) : (
              <button className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5">
                Please wait...
              </button>
            )
          ) : page === "2" ? (
            disablebtn === false ? (
              <button
                className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5"
                onClick={submitbtn}
              >
                Submit
              </button>
            ) : (
              <button className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5">
                Please wait...
              </button>
            )
          ) : disablebtn === false ? (
            <button
              className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5"
              onClick={submit_btn}
            >
              Submit
            </button>
          ) : (
            <button className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5">
              Please wait...
            </button>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Course;
